var windowWidth, windowHeight, documentWidth, documentHeight, topScrollPosition;
var siteRoot = window.location.protocol + "//" + window.location.host;

jQuery(document).ready(function($){

    function recalculateSizes() {
        windowWidth = $(window).width(); //retrieve current window width
        windowHeight = $(window).height(); //retrieve current window height
        documentWidth = $(document).width(); //retrieve current document width
        documentHeight = $(document).height(); //retrieve current document height
        topScrollPosition = $(window).scrollTop(); //retrieve the document scroll ToP position
    }
    recalculateSizes();

    $('form.nk-register-form').on('submit',function(e){
        e.preventDefault();
        let thisForm = $(this);
        // console.log(thisForm);
        let checkboxes = 'ok';
        if($('form.nk-register-form .nk-check:checked').length < 2) {
            checkboxes = 'not-ok';
        }

        thisForm.find('.sub-modal').html('<p class="processing">processing ...</p>');
        let user = {
            email : thisForm.find('.nk-email').val(),
            name : thisForm.find('.nk-name').val(),
            slug : thisForm.find('.nk-slug').val(),
            redirection : thisForm.find('.nk-redirection').val(),
            checkboxes : checkboxes,
        }

        $.ajax({
            type:'POST',
            dataType:'json',
            url:nk_ajax_user_object.ajaxurl,
            data:{
                'action':'nk_ajax_user_register',
                'user': user,
                'security': nk_ajax_user_object.nkNonce,
            },
            success:function(data){
                // console.log('returned user :', data);
                if(data.errors == 0){
                    // redirection here
                    $(location).attr('href', data.redirect_success_url);
                } else {
                    // console.log(data.errors);
                    thisForm.find('.sub-modal').html(data.errors);
                    // alert(data.errors);
                }
                // thisForm.find('.sub-modal').text('data-complete');
            },
            error:function(xhr, ajaxOptions, thrownError) {
                // fire something like we had a problem with your request, please try again later
                alert(xhr.status);
                alert(thrownError);
            },
            always:function() {
                // thisForm.find('.sub-modal').text('data-complete');
            }
        });

    });

    // console.log('aweber_tags_array', aweber_tags_array);
    aweber_tags_array.forEach(element => {
        $('.' + element.tag_name)
            .attr('attr-aweber', element.tag_name)
            .attr('attr-redirection', element.redirection)
            .attr('attr-title', element.popup_title)
            .attr('attr-button', element.popup_button);
        // console.log(element);
    });

    function popupOpen() {
        $('body').addClass('nk-popup-opened');
    }

    function popupClose() {
        $('body').removeClass('nk-popup-opened');
    }

    $('body').on('click', '[attr-aweber]', function(e) {
        e.preventDefault();
        let redirectionId = $(this).attr('attr-redirection');
        const popupTitle = $(this).attr('attr-title');
        const popupButtonText = $(this).attr('attr-button');
        let popup = $('.nk-register-popup');
        // fb: https://frt.lcg.design/?wc-api=auth&start=facebook&return=https%3A%2F%2Fmystore.com%2Fmy-account
        let socialFacebookUrlRedirection = siteRoot + '/?wc-api=auth&start=facebook&return=' + encodeURIComponent(aweber_url_array[redirectionId]);
        popup.find('.popup-title').html(popupTitle);
        popup.find('.nk-submit').text(popupButtonText);
        popup.find('.logged-in-button').text(popupButtonText);
        popup.find('input.nk-redirection').val(redirectionId);
        popup.find('.button-social-login-facebook').attr('href', socialFacebookUrlRedirection);
        popup.find('.logged-in-button').attr('href', aweber_url_array[redirectionId]);
        // console.log(aweber_url_array[redirectionId]);

        //
        // do all the animations with popup
        //
        popupOpen();

    });
    $('body').on('click', '.nk-popup-close', function() {
        popupClose();
    });

    // popup actions
    $('.nk-register-popup .nk-check').on('change', function() {
        if($('.nk-register-popup .nk-check:checked').length == 2) {
            $('.nk-register-popup').removeClass('not-fully-active');
        } else {
            $('.nk-register-popup').addClass('not-fully-active');
        }
        $(this).closest('.nk-label').removeClass('not-checked');
    });

    // execute on resize but only once
    var doResize;
    $(window).on('resize', function() {

        clearTimeout(doResize);
        doResize = setTimeout(function() {

        }, 300);
    });

    var doScroll;
    $(window).on('scroll', function() {

        clearTimeout(doScroll);
        doScroll = setTimeout(function() {

        }, 300);
    });

    $( window ).on( "orientationchange", function( event ) {

    });

});


jQuery(window).on('load', function() {
    // deferImages();
});